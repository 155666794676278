<!-- Update Metatags -->
<view-title>bergauf.ch Impressum: Verantwortlichkeiten für Inhalt und Realisierung</view-title>
<meta data-view-head name="description" content="Verantwortlichkeiten für Inhalt, Design und Realisierung von bergauf.ch" />
<meta data-view-head property="og:title" content="bergauf.ch Impressum: Verantwortlichkeiten für Inhalt und Realisierung" />
<meta data-view-head property="og:description" content="Verantwortlichkeiten für Inhalt, Design und Realisierung von bergauf.ch" />
<meta data-view-head property="og:url" content="{{home.serverUrl.host}}/impressum" />
<link data-view-head rel="canonical" href="https://www.bergauf.ch/impressum" >

<!-- Page Heading/Breadcrumbs -->
<div class="row">
  <div class="col-lg-12">
    <h1 class="page-header lazy">Impressum</h1>
  </div>
</div>
<!-- /.row -->

<!-- start impressum http://www.bag.ch/impressum-generator -->

<!-- Content Row -->
<div class="row">
  <div class="col-lg-12">
    <div class="panel-group" role="tablist" aria-multiselectable="true" bs-collapse>
      <div class="panel panel-default" ng-model="panels.activePanel" >
        <div class="panel-heading" role="tab">
          <h4 class="panel-title">
            <a bs-collapse-toggle>Verantwortlich für den Inhalt, Design und Realisierung</a>
          </h4>
        </div>
        <div class="panel-collapse" role="tabpanel" bs-collapse-target>
          <div class="panel-body h-card">
            <p><span class="p-name">Roland Aeschimann</span><br/>
              <span class="p-street-address">Feldeggstrasse 33</span><br/>
              <span class="p-postal-code">8008</span> <span class="p-locality">Zürich</span><br/>
              <span class="p-country-name">Schweiz</span></p>
            <p><a href="contact">info|[at]bergauf.ch</a></p>
            <p>Programmierung: Roland Aeschimann & Erol Ünala</p>
          </div>
        </div>
      </div>
      <div class="panel panel-default">
        <div class="panel-heading" role="tab">
          <h4 class="panel-title">
            <a bs-collapse-toggle>Haftungsausschluss</a>
          </h4>
        </div>
        <div class="panel-collapse" role="tabpanel" bs-collapse-target>
          <div class="panel-body">
            <p>Der Autor übernimmt keinerlei Gewähr hinsichtlich der inhaltlichen Richtigkeit, Genauigkeit, Aktualität,
              Zuverlässigkeit und Vollständigkeit der Informationen.</p>
            <p>Haftungsansprüche gegen den Autor wegen Schäden materieller oder immaterieller Art, welche aus dem
              Zugriff oder der Nutzung bzw. Nichtnutzung der veröffentlichten Informationen, durch Missbrauch der
              Verbindung oder durch technische Störungen entstanden sind, werden ausgeschlossen.</p>
            <p>Alle Angebote sind unverbindlich. Der Autor behält es sich ausdrücklich vor, Teile der Seiten oder das
              gesamte Angebot ohne gesonderte Ankündigung zu verändern, zu ergänzen, zu löschen oder die
              Veröffentlichung zeitweise oder endgültig einzustellen.</p>
          </div>
        </div>
      </div>
      <div class="panel panel-default">
        <div class="panel-heading" role="tab">
          <h4 class="panel-title">
            <a bs-collapse-toggle>Haftung für Links</a>
          </h4>
        </div>
        <div class="panel-collapse" role="tabpanel" bs-collapse-target>
          <div class="panel-body">
            <p>Verweise und Links auf Webseiten Dritter liegen ausserhalb unseres Verantwortungsbereichs. Es wird
              jegliche Verantwortung für solche Webseiten abgelehnt. Der Zugriff und die Nutzung solcher Webseiten
              erfolgen auf eigene Gefahr des Nutzers oder der Nutzerin.</p>
          </div>
        </div>
      </div>
      <div class="panel panel-default">
        <div class="panel-heading" role="tab">
          <h4 class="panel-title">
            <a bs-collapse-toggle>Urheberrechte</a>
          </h4>
        </div>
        <div class="panel-collapse" role="tabpanel" bs-collapse-target>
          <div class="panel-body">
            <p>Die Urheber- und alle anderen Rechte an Inhalten, Bildern, Fotos oder anderen Dateien auf der Website
              gehören ausschliesslich <strong>Roland Aeschimann</strong> oder den speziell genannten Rechtsinhabern. Für
              die Reproduktion jeglicher Elemente ist die schriftliche Zustimmung der Urheberrechtsträger im Voraus
              einzuholen.</p>
          </div>
        </div>
      </div>
      <div class="panel panel-default">
        <div class="panel-heading" role="tab">
          <h4 class="panel-title">
            <a bs-collapse-toggle>Datenschutz</a>
          </h4>
        </div>
        <div class="panel-collapse" role="tabpanel" bs-collapse-target>
          <div class="panel-body">
            <p>Gestützt auf Artikel 13 der schweizerischen Bundesverfassung und die datenschutzrechtlichen Bestimmungen
              des Bundes (Datenschutzgesetz, DSG) hat jede Person Anspruch auf Schutz ihrer Privatsphäre sowie auf
              Schutz vor Missbrauch ihrer persönlichen Daten. Wir halten diese Bestimmungen ein. Persönliche Daten
              werden streng vertraulich behandelt und weder an Dritte verkauft noch weiter gegeben.</p>
            <p>In enger Zusammenarbeit mit unseren Hosting-Providern bemühen wir uns, die Datenbanken so gut wie möglich
              vor fremden Zugriffen, Verlusten, Missbrauch oder vor Fälschung zu schützen.</p>
            <p>Beim Zugriff auf unsere Webseiten werden folgende Daten in Logfiles gespeichert: IP-Adresse, Datum,
              Uhrzeit, Browser-Anfrage und allg. übertragene Informationen zum Betriebssystem resp. Browser. Diese
              Nutzungsdaten bilden die Basis für statistische, anonyme Auswertungen, so dass Trends erkennbar sind,
              anhand derer wir unsere Angebote entsprechend verbessern können.</p>
          </div>
        </div>
      </div>
      <div class="panel panel-default">
        <div class="panel-heading" role="tab">
          <h4 class="panel-title">
            <a bs-collapse-toggle>Datenschutzerklärung für die Nutzung von Google Analytics</a>
          </h4>
        </div>
        <div class="panel-collapse" role="tabpanel" bs-collapse-target>
          <div class="panel-body">
            <p> Diese Website benutzt Google Analytics, einen Webanalysedienst der Google Inc. ("Google"). Google
              Analytics verwendet sog. "Cookies", Textdateien, die auf Ihrem Computer gespeichert werden und die eine
              Analyse der Benutzung der Website durch Sie ermöglichen. Die durch den Cookie erzeugten Informationen über
              Ihre Benutzung dieser Website werden in der Regel an einen Server von Google in den USA übertragen und
              dort gespeichert. Im Falle der Aktivierung der IP-Anonymisierung auf dieser Webseite wird Ihre IP-Adresse
              von Google jedoch innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des
              Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt.</p>

            <p>Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort
              gekürzt. Google wird diese Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports
              über die Websiteaktivitäten für die Websitebetreiber zusammenzustellen und um weitere mit der
              Websitenutzung und der Internetnutzung verbundene Dienstleistungen zu erbringen. Auch wird Google diese
              Informationen gegebenenfalls an Dritte übertragen, sofern dies gesetzlich vorgeschrieben oder soweit
              Dritte diese Daten im Auftrag von Google verarbeiten. Die im Rahmen von Google Analytics von Ihrem Browser
              übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt.</p>

            <p>Sie können die Installation der Cookies durch eine entsprechende Einstellung Ihrer Browser Software
              verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche
              Funktionen dieser Website voll umfänglich nutzen können. Durch die Nutzung dieser Website erklären Sie
              sich mit der Bearbeitung der über Sie erhobenen Daten durch Google in der zuvor beschriebenen Art und
              Weise und zu dem zuvor benannten Zweck einverstanden.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /.col-lg-12 -->
</div>
<!-- /.row -->

<!-- end impressum -->
