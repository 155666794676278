///<reference path='../../min.references.ts' />

module Home.Data {
  'use strict';

  export interface IBookmarks {

    "products": Array<Home.Data.IProduct>;
  }

}
