///<reference path='../../../typings/tsd.d.ts' />
module Home.Data {
  'use strict';

  export interface IImage {

    "filename": string;
    "width": number;
    "height": number;
    "caption": string;
    "description": string;
    "alt": string;

  }

}


