///<reference path='../../min.references.ts' />
module Home.BookmarksCtrl {
  'use strict';

  class BookmarksCtrl {

    ctrlName: string;
    deleteProduct: any; // points to the function, makes it a public function.
    bookmarks: Home.Data.IBookmarks;
    products: Array<Home.Data.IProduct>;

    // $inject annotation.
    public static $inject = [
      'BookmarksService',
      '$log',
      '$location',
      'Logger'
    ];


    // dependencies are injected via AngularJS $injector
    constructor(private BookmarksService: Home.Services.IBookmarksService,
                private $log: ng.ILogService,
                private $location: ng.ILocationService,
                private Logger : Logger.ILoggerService) {
      var vm = this; // initialize class variables
      vm.ctrlName = 'BookmarksCtrl';
      vm.bookmarks = null;
      vm.products = [];
      vm.deleteProduct = deleteProduct; // points to the function, makes it a public function.


      this.getBookmarks();


      function deleteProduct(product) {
        vm.products =  this.BookmarksService.removeProduct(product);
        vm.bookmarks.products = vm.products;
      }

    }


    private getBookmarks():void {
      this.bookmarks = this.BookmarksService.getBookmarks(); // get bookmarks-object
      for (var i=0; i < this.bookmarks.products.length; i++) { // get all products in bookmarks-object
        this.products.push(this.bookmarks.products[i]);
      }
      //this.$log.debug("bookmarks-controller getFavorites():  " + this.products.length);
    }


  }


  /**
   * @ngdoc object
   * @name home.controller:BookmarksCtrl
   *
   * @descriptionme
   *
   */
  angular
    .module('home')
    .controller('BookmarksCtrl', BookmarksCtrl);
}
