///<reference path='../../min.references.ts' />
module Home.GalleryDetailCtrl {
  'use strict';

  interface IGalleryRouteParams extends ng.route.IRouteParamsService {
    galleryLabel: string;
  }

  class GalleryDetailCtrl {

    ctrlName: string;
    galleryLabel: string;
    gallery: Home.Data.IGallery;
    galleryImages: Array<Home.Data.IImage>;
    metaTitle: string;
    metaDescription: string;
    metaKeywords: string;
    ogImage:string;
    isGallery: boolean;
    addBookmark: any;
    tags: any;
    serverUrl: any;
    canonicalUrl: any;
    cartButtonTooltip: string;
    scrollToTop: any;
    activityHeaderExtension: string;
    addBreadcrumb: any;


    // $inject annotation.
    // It provides $injector with information about dependencies to be injected into constructor
    // it is better to have it close to the constructor, because the parameters must match in count and type.
    // See http://docs.angularjs.org/guide/di
    public static $inject = [
      '$window',
      '$log',
      '$routeParams',
      '$http',
      'Repository',
      'BookmarksService',
      'Tagging',
      '$q',
      '$filter',
      'serverUrl',
      '$rootScope',
      '$anchorScroll'];

    // dependencies are injected via AngularJS $injector
    constructor(private $window : ng.IWindowService,
                private $log : ng.ILogService,
                private $routeParams : IGalleryRouteParams,
                private $http : ng.IHttpService,
                private repository : Home.Services.IRepository,
                private BookmarksService: Home.Services.IBookmarksService,
                private tagging: Home.Services.ITagging,
                private $q : ng.IQService,
                private $filter : ng.IFilterService,
                private _serverUrl_ : any,
                private $rootScope : any,
                private $anchorScroll : any) {

      // redirect page with .html extension to angular page
      if ($window.location.href.indexOf(".html") > 0) {
        $window.location.href = window.location.href.substring(0, $window.location.href.indexOf(".html"));
      }
      var vm = this;
      vm.ctrlName = 'GalleryDetailCtrl';
      vm.serverUrl = _serverUrl_;

      vm.tags = tagging.getTags();
      vm.gallery = repository.getGallery();
      vm.isGallery = (typeof(vm.gallery) === "object");
      vm.addBookmark = addBookmark;

      if (vm.isGallery) {
        vm.loadImages();
        vm.galleryLabel = $routeParams.galleryLabel;

        // validate some gallery attributes first, not every gallery has an altitudeLabel or region assigned
        var altitudeLabel = vm.gallery.altitudeLabel ? ' '+vm.gallery.altitudeLabel : '';
        var country = vm.gallery.country  ?
          $filter('convertIdToProperty')(vm.gallery.country, vm.tags, 'countries','name') : '';
        var region = vm.gallery.region ?
          $filter('convertIdToProperty')(vm.gallery.region, vm.tags, 'regions','name') : '';
        var activity = vm.gallery.activity[0] ?
          $filter('convertIdToProperty')(vm.gallery.activity[0], vm.tags, 'activities','name') : '';
        var year = parseInt(vm.gallery.date);
        vm.activityHeaderExtension = (activity != "Reise") ? activity : '';

        vm.metaTitle = activity + " " + vm.gallery.name + altitudeLabel + ' (' + country + ' - ' + region + ', ' + year +')';
        vm.metaDescription = 'Fotoalbum ' + activity + ' ' + vm.gallery.name + altitudeLabel + ' in der Region ' + region + ' - ' + country + ' im Outdoor Fotoblog (' + year +').';
        vm.metaKeywords = vm.gallery.name + ', ' + region + ', ' + country + ', '+ activity + ', Fotoblog, Outdoor';
        vm.ogImage = vm.gallery.preImage? vm.serverUrl.data + vm.gallery.path + "/" + vm.gallery.preImage : "";
        vm.canonicalUrl = vm.serverUrl.host + vm.gallery.path;



        vm.cartButtonTooltip = ($rootScope.globals && $rootScope.globals.currentUser) ?
          "zip File als Download aller Bilder dieser Galerie bestellen" :
          "Um ein zip File aller Bilder dieser Galerie zu bestellen bitte zuerst anmelden";

        vm.scrollToTop = function() {
          $anchorScroll();
          //console.log('scrollToTop in HomeCtrl called.');
        };

        // ToDo: better error handling / add 1 - n listitems
        if (region === "" ) {
        vm.addBreadcrumb = '{\n"@context": "http://schema.org",\n"@type": "BreadcrumbList",\n"itemListElement": ['+
          '\n{"@type": "ListItem",\n"position": 1,\n"item":\n{"@id": "/suche",\n"name": "Bildergalerien"}\n' + '},\n'+
          '\n{"@type": "ListItem",\n"position": 2,\n"item":\n{"@id": "/bildergalerien-nach-laender/'+vm.gallery.country+'",\n"name": "'+ country +'"}\n' + '},\n'+
           '\n{"@type": "ListItem",\n"position": 3,\n"item":\n{"@id": "/bildergalerien-nach-outdoor-aktivitaet/'+vm.gallery.activity[0]+'",\n"name": "'+ activity +'"}\n' + '}\n'+
          '\]'+'' +
          '\n}';
        } else {
          vm.addBreadcrumb = '{\n"@context": "http://schema.org",\n"@type": "BreadcrumbList",\n"itemListElement": ['+
            '\n{"@type": "ListItem",\n"position": 1,\n"item":\n{"@id": "/suche",\n"name": "Bildergalerien"}\n' + '},\n'+
            '\n{"@type": "ListItem",\n"position": 2,\n"item":\n{"@id": "/bildergalerien-nach-laender/'+vm.gallery.country+'",\n"name": "'+ country +'"}\n' + '},\n'+
            '\n{"@type": "ListItem",\n"position": 3,\n"item":\n{"@id": "/bildergalerien-nach-region/'+vm.gallery.region+'",\n"name": "'+ region +'"}\n' + '},\n'+
            '\n{"@type": "ListItem",\n"position": 4,\n"item":\n{"@id": "/bildergalerien-nach-outdoor-aktivitaet/'+vm.gallery.activity[0]+'",\n"name": "'+ activity +'"}\n' + '}\n'+
            '\]'+'' +
            '\n}';
        }
      }

      function addBookmark() {
        //vm.$log.debug("galleryDetailController.addToBookmark: " + vm.gallery.path);
        this.BookmarksService.addProduct({path: vm.gallery.path});
      }

    }

    // load image details from pics.json file for each gallery
    private loadImages() : ng.IPromise<any> {
      var deferred = this.$q.defer();
      var log = this.$log;
      if (!this.galleryImages) {
        var picsfile = this.serverUrl.data + this.gallery.path + '/pics.json';
        this.$http.get(picsfile).then((data) => {
          var pics = [];
          angular.forEach(data.data, function(pic) {
            pics.push(pic);
          });
          this.galleryImages = <Array<Home.Data.IImage>> pics;
          deferred.resolve(this.galleryImages);
        });
      } else {
        deferred.resolve(this.galleryImages);
      }
      return deferred.promise;
    }


  }


  /**
   * @ngdoc object
   * @name gallery.controller:GalleryDetailCtrl
   *
   * @description
   *
   */
  angular
    .module('home')
    .controller('GalleryDetailCtrl', GalleryDetailCtrl);
}
