<div class="jumbotron">
  <div class="container">
    <div class="col-sm-8 col-sm-offset-2">
      <div class="col-md-6 col-md-offset-3">
        <h2>Login</h2>
        <div ng-show="vm.error" class="alert alert-danger">{{vm.error}}</div>
        <form name="form" ng-submit="vm.login()" role="form">
          <div class="form-group" ng-class="{ 'has-error': form.username.$dirty && form.username.$error.required }">
            <label for="username">Email</label>
            <input type="email" name="username" id="username" class="form-control" ng-model="vm.username" required  placeholder="Email eingeben"/>
            <span ng-show="form.username.$dirty && form.username.$error.required && form.$invalid" class="help-block">Gültige Email eingeben</span>
          </div>
          <div class="form-group" ng-class="{ 'has-error': form.password.$dirty && form.password.$error.required }">
            <label for="password">Passwort</label>
            <input type="password" name="password" id="password" class="form-control" ng-model="vm.password" required  placeholder="Passwort eingeben" />
            <span ng-show="form.password.$dirty && form.password.$error.required" class="help-block">Passwort ist ein Mussfeld</span>
          </div>
          <div class="form-actions">
            <button type="submit" ng-disabled="vm.dataLoading" class="btn btn-primary">Login</button>
            <img ng-if="vm.dataLoading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
            <a href="register" class="btn btn-link">Neu Registrieren</a>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-lg-12">
    <p class="well">Vorteile mit einem Login: Bestellen Sie kostenlos hochauflösende Versionen Ihrer Lieblings-Bildergalerien (für den privaten Gebrauch).</p>
  </div>
</div>
