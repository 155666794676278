<div ng-cloak class="row">
  <div class="col-lg-12">
    <h1 class="page-header">{{vm.user.firstname}} {{vm.user.lastname}} <small>{{vm.user.username}}</small>
      <a href="login" class="btn btn-primary">Logout</a>
    </h1>
  </div>
</div>

<div class="row profile">
  <div ng-cloak class="col-lg-12">
    <h2 class="profile-header">Meine offenen Bestellungen</h2>
    <div class="panel-group" role="tablist" aria-multiselectable="true" bs-collapse ng-repeat="myuserorders in vm.userOrder">
      <div class="panel panel-default" ng-model="panels.activePanel" ng-repeat="myorder in myuserorders | filter:{processed:false}">
        <div class="panel-heading" role="tab">
          <h4 class="panel-title">
            <a bs-collapse-toggle>{{myorder.products.length}} <span ng-if="myorder.products.length > 1">Bildgalerien</span><span ng-if="myorder.products.length < 2">Bildgalerie</span>
              am {{myorder.ordered | date:'dd.MM.yyyy HH:mm:ss'}} bestellt
              </a>
          </h4>
        </div>
        <div class="panel-collapse" role="tabpanel" bs-collapse-target>
          <div class="panel-body">
            <ul class="list-unstyled">
              <li ng-repeat="myproduct in myorder.products">
                <gallery-item path="{{myproduct.path}}"></gallery-item>
              </li>
            </ul>
        </div>
      </div>
      </div>
    </div>
  </div>


  <div ng-cloak class="col-lg-12" ng-if="vm.admin">

    <h2 class="profile-header">Shop Administrator</h2>

    <h3 class="profile-subheader">Alle pendenten Bestellungen</h3>

      <div class="panel-group" role="tablist" aria-multiselectable="true" bs-collapse ng-repeat="userorders in vm.allOrders">
        <div class="panel panel-default" ng-model="panels.activePanel" ng-repeat="oneorder in userorders | filter:{processed:false}">
          <div class="panel-heading" role="tab">
            <h4 class="panel-title">
              <a bs-collapse-toggle>
                Bestellung von {{oneorder.username}} am {{oneorder.ordered | date:'dd.MM.yyyy HH:mm:ss' }}
              </a>
            </h4>
          </div>
          <div class="panel-collapse" role="tabpanel" bs-collapse-target>
            <div class="panel-body">
              <ul class="list-unstyled">
                <li ng-repeat="oneproduct in oneorder.products">
                  <gallery-item path="{{oneproduct.path}}"></gallery-item>
                </li>
              </ul>
              <a href="" ng-click="vm.markOrderProcessed(oneorder)" class="btn btn btn-success">
                <i class="fa fa-check-square-o fa-fw"></i> Als verarbeitet markieren</a>
            </div>
          </div>
        </div>
      </div>


    <h3 class="profile-subheader">Alle erledigten Bestellungen</h3>

      <div class="panel-group" role="tablist" data-start-collapsed="true" aria-multiselectable="true" bs-collapse ng-repeat="userorders in vm.allOrders">
        <div class="panel panel-default" ng-model="panels.activePanel" ng-repeat="oneorder in userorders | filter:{processed:true} | orderBy:'-ordered'">
          <div class="panel-heading" role="tab">
            <h4 class="panel-title">
              <a bs-collapse-toggle>
                Bestellung von {{oneorder.username}} am {{oneorder.ordered | date:'dd.MM.yyyy HH:mm:ss' }}
              </a>
            </h4>
          </div>
          <div class="panel-collapse" role="tabpanel" bs-collapse-target>
            <div class="panel-body">
              <ul class="list-unstyled">
                <li ng-repeat="oneproduct in oneorder.products">
                  <gallery-item path="{{oneproduct.path}}"></gallery-item>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>


    <h3 class="profile-subheader">Registrierte Benutzer verwalten</h3>

    <div class="panel-group" role="tablist" data-start-collapsed="true" aria-multiselectable="true" bs-collapse>
      <div class="panel panel-default"  ng-repeat="user in vm.allUsers">
        <div class="panel-heading" role="tab">
          <h4 class="panel-title">
            <a bs-collapse-toggle>
               {{user.firstname}} {{user.lastname}} ({{user.username}})
            </a>
          </h4>
        </div>
        <div class="panel-collapse" role="tabpanel" bs-collapse-target>
          <div class="panel-body">
            <dl class="dl-horizontal">
              <dt>Benutzername</dt><dd>{{user.username}}</dd>
              <dt>Vorname</dt><dd>{{user.firstname}}</dd>
              <dt>Nachname</dt><dd>{{user.lastname}}</dd>
            </dl>
            <a href="" ng-click="vm.deleteUser(user.id)" class="btn btn btn-danger">
              <i class="fa fa-trash fa-fw"></i> Benutzer löschen</a>
          </div>
        </div>
      </div>
    </div>

  </div>

</div>
