<div class="aside aside-cart ng-scope right am-slide-right" tabindex="-1" role="dialog" style="display: block;">
  <div class="aside-dialog">

    <div class="aside-content" ng-controller="BookmarksCtrl as fav">
      <div class="aside-header" ng-show="title">
        <button type="button" class="close" ng-click="$hide()"><i class="fa fa-times"></i></button>
        <h4 class="aside-title ng-binding" ng-bind="title">Meine Favoriten</h4>
      </div>

      <div ng-cloak class="aside-body">
        <div class="col-xs-*">
          <ul class="list-unstyled">
            <li class="cart-item" ng-repeat="product in fav.products">
              <a href="" ng-click="fav.deleteProduct(product);"><i class="fa fa-trash"></i></a>
              <gallery-item path="{{product.path}}" ng-click="$hide()"></gallery-item>
            </li>
          </ul>
        </div>
      </div>

      <div class="aside-footer">
        <button type="button" class="btn btn-default btn-sm" ng-click="$hide()">schliessen</button>
      </div>
    </div>

  </div>
</div>
