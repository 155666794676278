<!-- check for a valid gallery -->
<div ng-switch="galleryDetail.isGallery" ng-controller="GalleryDetailCtrl as galleryCtrl">
  <div ng-switch-when="true">

<!-- Update Metatags -->
    <view-title ng-bind="galleryDetail.metaTitle"></view-title>
    <meta data-view-head name="description" content="{{galleryDetail.metaDescription}}" />
    <meta data-view-head property="og:title" content="{{galleryDetail.metaTitle}}" />
    <meta data-view-head property="og:description" content="{{galleryDetail.metaDescription}}" />
    <meta ng-if="galleryDetail.ogImage" data-view-head property="og:image" content="{{galleryDetail.ogImage}}" />
    <meta data-view-head property="og:url" content="{{galleryDetail.serverUrl.host}}{{galleryDetail.gallery.path}}" />
    <link data-view-head rel="canonical" href="{{galleryDetail.canonicalUrl}}" />
    <script type="application/ld+json" ng-bind="galleryDetail.addBreadcrumb">

    </script>

<article itemscope itemtype="http://schema.org/ImageGallery">
  <h1 class="page-header" itemprop="name">{{galleryDetail.activityHeaderExtension}} {{galleryDetail.gallery.name}} <small>{{galleryDetail.gallery.altitudeLabel}}</small></h1>
  <div class="bergauf-flexgallery gallery" >
    <figure ng-repeat="image in galleryDetail.galleryImages" itemprop="associatedMedia" itemscope itemtype="http://schema.org/ImageObject">
      <a href="{{galleryDetail.serverUrl.data}}{{galleryDetail.gallery.path}}/{{image.filename}}" itemprop="contentUrl" data-size="{{image.width}}x{{image.height}}"
        data-analytics-on data-analytics-event="StartSlideshow" data-analytics-category="{{galleryDetail.gallery.name}}" data-analytics-label="{{image.filename}}" aria-label="Bild Nr. {{$index+1}} in der Galerie öffnen">
        <img class="lazy" data-original="{{galleryDetail.serverUrl.data}}/thumbnails{{galleryDetail.gallery.path}}/{{image.filename}}" itemprop="thumbnail" alt="" height="107" width="160" />
      </a>
      <figcaption itemprop="caption">
        {{image.description}} <span class="additional-semantics" itemprop="description">{{image.alt}}</span>
      </figcaption>
    </figure>
  </div>
  <ul class="list-inline">
    <li ng-if="galleryDetail.gallery.country">
      <i class="fa fa-globe fa-fw fa-lg fa-color"></i>
      <span ng-if="galleryDetail.gallery.region" itemprop="keywords">
        <a class="p-category" href="bildergalerien-nach-region/{{galleryDetail.gallery.region}}" ng-click="galleryDetail.scrollToTop();" data-analytics-on data-analytics-event="Tagging" data-analytics-category="Regions" data-analytics-label="{{galleryDetail.gallery.region}}">
          {{galleryDetail.gallery.region | convertIdToProperty:galleryDetail.tags:'regions':'name'}}</a>
      </span>
      <span ng-if="galleryDetail.gallery.region && galleryDetail.gallery.country"> &minus; </span>
      <span ng-if="galleryDetail.gallery.country" itemprop="keywords">
        <a href="bildergalerien-nach-laender/{{galleryDetail.gallery.country}}" ng-click="galleryDetail.scrollToTop();"
           data-analytics-on data-analytics-event="Tagging" data-analytics-category="Countries" data-analytics-label="{{galleryDetail.gallery.country}}">
        {{galleryDetail.gallery.country | convertIdToProperty:galleryDetail.tags:'countries':'name'}}</a>
      </span>
    </li>
    <li ng-if="galleryDetail.gallery.date">
      <i class="fa fa-calendar fa-fw fa-lg fa-color"></i>
      <span ng-if="galleryDetail.gallery.dateStart"><time datetime="{{galleryDetail.gallery.dateStart}}">{{galleryDetail.gallery.dateStart | date:'dd.MM. - '}}</time></span>
      <time datetime="{{galleryDetail.gallery.date}}" itemprop="dateCreated">{{galleryDetail.gallery.date | date:'dd.MM.yyyy'}}</time>
    </li>
    <!--li ng-if="galleryDetail.gallery.activity" itemprop="keywords">
      <ul class="list-inline list-inline_tags">
        <li ng-repeat="activity in galleryDetail.gallery.activity">
          <i class="fa fa-tag fa-fw fa-lg fa-color"></i>
          <a href="bildergalerien-nach-outdoor-aktivitaet/{{activity}}" data-analytics-on data-analytics-event="Tagging" data-analytics-category="Activities" data-analytics-label="{{activity}}">
            {{activity | convertIdToProperty:galleryDetail.tags:'activities':'name'}}</a>
        </li>
      </ul>
    </li-->
    <li>
      <i class="fa fa-bookmark fa-fw fa-lg fa-color"></i>
        <a href="#" ng-click="galleryCtrl.addBookmark()" data-analytics-on data-analytics-event="AddBookmark" data-analytics-category="{{galleryDetail.gallery.name}}" data-analytics-label="{{galleryDetail.gallery.activity}}">merken</a>
    </li>
  </ul>
</article>
<span class="photoswipe"></span>

<hr>
<aside>
<ul class="list-inline">
  <li>Verwandte Galerien</li>
  <!--li ng-if="galleryDetail.gallery.region">
    <i class="fa fa-globe fa-fw fa-lg fa-color"></i>
    <a href="bildergalerien-nach-region/{{galleryDetail.gallery.region}}" ng-click="galleryDetail.scrollToTop();">
      {{galleryDetail.gallery.region | convertIdToProperty:galleryDetail.tags:'regions':'name'}}</a>
  </li-->
  <li ng-if="galleryDetail.gallery.activity">
    <ul class="list-inline list-inline_tags">
      <li ng-repeat="activity in galleryDetail.gallery.activity">
        <i class="fa fa-tag fa-fw fa-lg fa-color"></i>
        <a href="bildergalerien-nach-outdoor-aktivitaet/{{activity}}" data-analytics-on data-analytics-event="Tagging" data-analytics-category="Activities" data-analytics-label="{{activity}}" ng-click="galleryDetail.scrollToTop();">
          {{activity | convertIdToProperty:galleryDetail.tags:'activities':'name'}}</a>
      </li>
    </ul>
  </li>
</ul>
</aside>
<!-- Root element of PhotoSwipe. Must have class pswp. -->
    <div id="gallery" class="pswp" tabindex="-1" role="dialog" aria-hidden="true">

      <!-- Background of PhotoSwipe.
           It's a separate element as animating opacity is faster than rgba(). -->
      <div class="pswp__bg"></div>

      <!-- Slides wrapper with overflow:hidden. -->
      <div class="pswp__scroll-wrap">

        <!-- Container that holds slides.
            PhotoSwipe keeps only 3 of them in the DOM to save memory.
            Don't modify these 3 pswp__item elements, data is added later on. -->
        <div class="pswp__container">
          <div class="pswp__item"></div>
          <div class="pswp__item"></div>
          <div class="pswp__item"></div>
        </div>

        <!-- Default (PhotoSwipeUI_Default) interface on top of sliding area. Can be changed. -->
        <div class="pswp__ui pswp__ui--hidden">

          <div class="pswp__top-bar">

            <!--  Controls are self-explanatory. Order can be changed. -->

            <div class="pswp__counter"></div>
            <button class="pswp__button pswp__button--close" title="Schliessen (Esc)"></button>
            <button class="pswp__button pswp__button--share" title="Teilen"></button>

            <!-- Preloader demo http://codepen.io/dimsemenov/pen/yyBWoR -->
            <!-- element will get class pswp__preloader__active when preloader is running -->
            <div class="pswp__preloader">
              <div class="pswp__preloader__icn">
                <div class="pswp__preloader__cut">
                  <div class="pswp__preloader__donut"></div>
                </div>
              </div>
            </div>
          </div>

          <div class="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">
            <div class="pswp__share-tooltip"></div>
          </div>

          <button class="pswp__button pswp__button--arrow--left" title="Previous (arrow left)">
          </button>

          <button class="pswp__button pswp__button--arrow--right" title="Next (arrow right)">
          </button>

          <div class="pswp__caption">
            <div class="pswp__caption__center"></div>
          </div>

        </div>

      </div>

    </div>


  </div>
  <!-- display 'gallery not found' error for non valid url's -->
  <div ng-switch-default>
    <meta data-view-head name="robots" content="noindex" />
    <div class="jumbotron">
      <h1>Keine Bildergalerie gefunden.</h1>
      <h2>Versuche es mit einer Suche auf der Startseite.</h2>
      <p><a class="btn btn-primary btn-lg" href="/index" role="button">Zurück zur Startseite</a></p>
    </div>
  </div>
</div>
