///<reference path='min.references.ts' />
module bergauf {
  'use strict';

  /* @ngdoc object
   * @name bergauf
   * @description
   *
   */
  angular
    .module('bergauf', [
      'ngRoute',
      'viewhead',
      'ngAnimate',
      'mgcrea.ngStrap',
      'dcbImgFallback',
      'LocalStorageModule',
      'home'
    ])
    .config(function (localStorageServiceProvider) {
      localStorageServiceProvider
        .setPrefix('bergauf')
        .setStorageType('localStorage')
        .setNotify(true, true)
    });
  /*
    .config(function($sceProvider) {
      // Completely disable SCE.  For demonstration purposes only!
      // Do not use in new projects or libraries.
      $sceProvider.enabled(false);
    });

   */
/*
    .config(['$httpProvider', function ($httpProvider: ng.IHttpProvider) {
      $httpProvider.interceptors.push(Home.Factories.AuthenticationInterceptor.Factory);
    }])
*/

}
