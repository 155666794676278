///<reference path='../../min.references.ts' />
module Home.DefaultCtrl {
  'use strict';

  class DefaultCtrl {

    ctrlName: string;
    serverUrl: any;
    canonicalUrl: any;

    // $inject annotation.
    // It provides $injector with information about dependencies to be injected into constructor
    // it is better to have it close to the constructor, because the parameters must match in count and type.
    // See http://docs.angularjs.org/guide/di
    public static $inject = ['$window','serverUrl'];

    // dependencies are injected via AngularJS $injector
    constructor(private $window : ng.IWindowService,
                private _serverUrl_: any) {

      // redirect page with .html extension to angular page
      if ($window.location.href.indexOf(".html") > 0) {
        $window.location.href = window.location.href.substring(0, $window.location.href.indexOf(".html"));
      }

      var vm = this;
      vm.ctrlName = 'DefaultCtrl';
      vm.serverUrl = _serverUrl_;
    }
  }


  /**
   * @ngdoc object
   * @name home.controller:DefaultCtrl
   *
   * @description
   *
   */
  angular
    .module('home')
    .controller('DefaultCtrl', DefaultCtrl);
}
