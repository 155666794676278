///<reference path='min.references.ts' />
module bergauf {
  'use strict';

  angular
    .module('bergauf')
    .config(config);

  function config($routeProvider: ng.route.IRouteProvider) {
    $routeProvider.otherwise({
      redirectTo: '/index'
    });
  }
}
