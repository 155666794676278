<div class="aside aside-cart ng-scope right am-slide-right" tabindex="-1" role="dialog" style="display: block;">
  <div class="aside-dialog">

    <div class="aside-content" ng-controller="CartCtrl as cart">
      <div class="aside-header" ng-show="title">
        <button type="button" class="close" ng-click="$hide()"><i class="fa fa-times"></i></button>
        <h4 class="aside-title ng-binding" ng-bind="title">Dein Warenkorb</h4>
      </div>

      <div ng-cloak class="aside-body">
        <div class="col-xs-*">
          <p><i class="fa fa-user fa-lg"></i> {{cart.user.username}}</p>
          <ul class="list-unstyled">
            <li class="cart-item" ng-repeat="product in cart.products">
              <a href="" ng-click="cart.deleteProduct(product);"><i class="fa fa-trash"></i></a>
              <gallery-item path="{{product.path}}"></gallery-item>
            </li>
          </ul>
        </div>
        <div class="col-xs-* text-right">
          <a href="" class="btn btn-primary" ng-disabled="vm.dataLoading || cart.products.length <1" ng-click="cart.checkout();$hide()">Checkout</a>
        </div>
      </div>

      <div class="aside-footer">
        <button type="button" class="btn btn-default btn-sm" ng-click="$hide()">schliessen</button>
      </div>
    </div>

  </div>
</div>
