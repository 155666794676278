///<reference path='../min.references.ts' />
module home {
  'use strict';

  /* @ngdoc object
  * @name home
  * @description
  *
  */
  angular
    .module('home', [
      'ngRoute',
      'ngStorage',
      'ngCookies',
      'infinite-scroll'
    ]);
}
