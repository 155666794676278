///<reference path='../../min.references.ts' />
module Home.Services {
  'use strict';

  export interface IBookmarksService { // declare public accessible functions and variables
    get(): string;
    getBookmarks(): Home.Data.IBookmarks;
    addProduct(product: Home.Data.IProduct): Array<Home.Data.IProduct>;
    removeProduct(product: Home.Data.IProduct): Array<Home.Data.IProduct>;
    getNumberOfProducts(): number;
    emptyCart(): void;
  }

  // service to handle a shopping cart's data in a cookie on the client side.
  class BookmarksService {

    public static $inject = [
      'localStorageService',
      '$log',
      '$http',
      'Logger',
      'serverUrl'
    ];


    private localstorageKey: string = 'favoriteGalleries';

    constructor(private localStorageService: any,
                private $log: ng.ILogService,
                private $http: ng.IHttpService,
                private logger : Logger.ILoggerService,
                private serverUrl : any) {
    }

    // read bookmarks from local storage into a bookmarks-object.
    getBookmarks(): Home.Data.IBookmarks {
      var products = this.readBookmarks();
      var bookmarks: Home.Data.IBookmarks = {products: []};

      for (var i=0; i < products.length; i++) {
        bookmarks.products.push(products[i]);
      }

      return bookmarks;
    }

    // add a product to the bookmarksCookie
    addProduct(product): Array<Home.Data.IProduct> {

      var products = this.readBookmarks();
      if(!this.containsProduct(products, product)) {

        products.push({ // add product
          path: product.path
        });
        this.logger.logSuccess('Diese Bildergalerie wurde zu den Favoriten hinzugefügt.', 'empty', this, true);
        //this.$log.debug("BookmarksService.addProduct(): " + product.path + " added.");

      } else {
        this.logger.logWarning('Diese Bildergalerie ist schon ein Favorit.', 'empty', this, true);
        //this.$log.debug("BookmarksService.addProduct(): " + product.path + " is already a favorite.");
      }

      this.writeBookmarks(products);

      return products;
    }

    // remove a product from the bookmarksCookie
    removeProduct(product): Array<Home.Data.IProduct> {
      var products = this.readBookmarks();

      for (var i=0; i < products.length; i++) { // remove product
        if (product.path == products[i].path) {
          products.splice(i, 1);
          this.logger.logSuccess('Die Bildergalerie wurde von den Favoriten gelöscht.', 'empty', this, true);
          //this.$log.debug("BookmarksService.removeProduct(): " + product.path + " removed.");
        }
      }
      this.writeBookmarks(products);
      return products;
    }

    getNumberOfProducts(): number {
      var products = this.readBookmarks();
      //this.$log.debug("BookmarksService.getNumberOfProducts(): " + products.length);
      return products.length;
    }

    emptyBookmarks(): void {
      this.localStorageService.remove(this.localstorageKey);
      //this.$log.debug("BookmarksService.emptyBookmarks() called.");
    }

    get(): string { // return the name of this service
      return 'BookmarksService';
    }

    // private functions

    handleSuccess(data) {
      return data;
    }

    handleError(error) {
      return function () {
        return {success: false, message: error};
      };
    }

    containsProduct(array : Array<Home.Data.IProduct>, elem: Home.Data.IProduct): boolean{
      var len = array.length;
      for(var i = 0 ; i < len;i++)
      {
        if(array[i].path == elem.path){
          return true;}
      }
      return false;
    }


    readBookmarks(): Array<Home.Data.IProduct> {
      if(!(this.localStorageService.get(this.localstorageKey) instanceof Array)) {
        this.localStorageService.set(this.localstorageKey, []);
      }
      var products = this.localStorageService.get(this.localstorageKey);
      //this.$log.debug("BookmarksService.readBookmarks(): Products " + products.length);
      return products;
    }

    writeBookmarks(products: Array<Home.Data.IProduct>): void {
      this.localStorageService.set(this.localstorageKey, products);
    }


  }

  /**
   * @ngdoc service
   * @name home.service:CartService
   *
   * @description
   *
   */
  angular
    .module('home')
    .service('BookmarksService', BookmarksService);

}
