///<reference path='../../../typings/tsd.d.ts' />
module SwisstopoMap {
  'use strict';

  /**
  * @ngdoc directive
  * @name home.directive:swisstopoMap
  * @restrict EA
  * @element
  *
  * @description
  *
  * @example
    <example module="home">
      <file name="index.html">
        <swisstopo-map></swisstopo-map>
      </file>
    </example>
  *
  */
  angular
    .module('home')
    .directive('swisstopoMap', swisstopoMap);

  function swisstopoMap($timeout): ng.IDirective {
    return {
      restrict: 'EA',
      scope: {},
      templateUrl: 'home/directives/swisstopo-map-directive.tpl.html',
      replace: false,
      controllerAs: 'swisstopoMap',
      controller: function () {
        var vm = this;
        vm.name = 'swisstopoMap';
      },
      link: function (scope: ng.IScope, element: JQuery, attrs: any) {
        /*jshint unused:false */
        /*eslint "no-unused-vars": [2, {"args": "none"}]*/
        /*
        $timeout(function () {

          var y = galleryDetail.gallery.map.y;
          var x = galleryDetail.gallery.map.x;

          console.log('y: ' + y);
          console.log('x: ' + x);

          var layer = ga.layer.create('ch.swisstopo.pixelkarte-farbe');
          var map = new ga.Map({
            target: 'map',
            layers: [layer],
            view: new ol.View({
              resolution: 500,
              center: [y,x]
              center: [712785.19,190993.05]
            })
          });

        }, 1000);
        */
      }
    };
  }
}
