<section>
  <div class="media">
    <div class="media-left">
      <a ng-cloak href="{{galleryObj.path}}" title="Weiter zu der {{galleryObj.name}} Bildergalerie" data-analytics-on data-analytics-event="GoToGallery" data-analytics-category="{{galleryObj.name}}" data-analytics-label="FromIndex">
        <img class="lazy media-object" data-original="{{serverUrl.data}}{{galleryObj.icon}}" alt="" height="120" width="160" />
      </a>
    </div>
    <div class="media-body">
      <h1 class="media-heading">
        <a title="Weiter zu der {{galleryObj.name}} Bildergalerie" href="{{galleryObj.path}}"
           data-analytics-on data-analytics-event="GoToGallery" data-analytics-category="{{galleryObj.name}}" data-analytics-label="FromIndex"><span ng-if="galleryObj.navTitle">{{galleryObj.navTitle}}</span><span ng-if="!galleryObj.navTitle">{{galleryObj.name}}</span>
        </a>
      </h1>
      <p ng-if="galleryObj.date" class="media-heading media-heading--small media-heading--light"><i class="fa fa-calendar fa-fw fa-colorhidden-xs"></i>
        <span ng-if="galleryObj.dateStart"><time datetime="{{galleryObj.dateStart}}">{{galleryObj.dateStart | date:'dd.MM. - '}}</time></span>
        <time datetime="{{galleryObj.date}}">{{galleryObj.date | date:'dd.MM.yyyy'}}</time>
      </p>
      <h2 class="media-heading media-heading--small media-heading--transform">
        <i class="fa fa-globe fa-fw fa-color"></i>
        <span ng-if="galleryObj.region">
            {{galleryObj.region | convertIdToProperty:tagsObj:'regions':'name'}}
        </span>
        <span ng-if="galleryObj.region && galleryObj.country"> &minus; </span>
        <span ng-if="galleryObj.country">
          {{galleryObj.country | convertIdToProperty:tagsObj:'countries':'code'}}
        </span>
      </h2>
      <span ng-repeat="activity in galleryObj.activity">
              <h2 ng-if="galleryObj.activity && $index <2" class="media-heading media-heading--small hidden-xs">
          <i class="fa fa-tags fa-fw fa-color"></i>
             {{activity | convertIdToProperty:tagsObj:'activities':'name'}}
              </h2>
        </span>
    </div>
  </div>
</section>
