<!-- Content Row for swisstopo maps-->
<div class="row" ng-if="galleryDetail.gallery.map">
  <div class="col-lg-12">
    <div class="panel-group" role="tablist" aria-multiselectable="true" bs-collapse>

      <div class="panel panel-default" ng-model="panels.activePanel" >
        <div class="panel-heading" role="tab">
          <h4 class="panel-title">
            <a bs-collapse-toggle>swisstopo Karte {{galleryDetail.gallery.name}}<span ng-if="galleryDetail.gallery.region"> - <a href="bildergalerien-nach-region/{{galleryDetail.gallery.region}}">
              {{galleryDetail.gallery.region | convertIdToProperty:galleryDetail.tags:'regions':'name'}}</a></span></a>
          </h4>
        </div>
        <div class="panel-collapse" role="tabpanel" bs-collapse-target data-mapx="{{galleryDetail.gallery.map.x}}" data-mapy="{{galleryDetail.gallery.map.y}}" data-mapzoom="{{galleryDetail.gallery.map.zoom}}">
          <div class="panel-body">
            <!--script src="http://api3.geo.admin.ch/loader.js?lang=en&mode=debug" type="text/javascript"></script-->
            <div id="map" class="map"></div>
          </div>
        </div>

      </div>
    </div>
    <!-- /.col-lg-12 -->
</div>
