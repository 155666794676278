<!-- Update Metatags -->
<view-title>bergauf.ch Kontakt: Wir freuen uns auf Feedback aller Art"</view-title>
<meta data-view-head name="description" content="bergauf.ch freut sich auf Feedback oder Kontaktaufnahme via Kontaktformular" />
<meta data-view-head property="og:title" content="bergauf.ch Kontakt: Wir freuen uns auf Feedback aller Art" />
<meta data-view-head property="og:description" content="bergauf.ch freut sich auf Feedback oder Kontaktaufnahme via Kontaktformular" />
<meta data-view-head property="og:url" content="{{contact.serverUrl.host}}/contact" />
<link data-view-head rel="canonical" href="{{contact.canonicalUrl}}" />

<!-- Page Heading/Breadcrumbs -->
<div class="row">
  <div class="col-lg-12">
    <h1 class="page-header lazy">Kontakt</h1>
  </div>
</div>
<!-- /.row -->


<div class="row">
  <div class="col-lg-12">
    <div class="panel panel-default">
      <div class="panel-heading">
        <h2 class="panel-title">Sende uns eine Nachricht (* Pflichtfelder)</h2>
      </div>

      <div class="panel-body">
        <form ng-submit="submit(contactform, $event)" name="contactform" method="post" action="" class="form-horizontal" role="form" novalidate>
          <div class="form-group" ng-class="{ 'has-error': contactform.inputName.$invalid && submitted }">
            <label for="inputName" class="col-lg-2 control-label">Name *</label>
            <div class="col-lg-10">
              <input ng-model="formData.inputName" type="text" class="form-control" id="inputName" name="inputName" placeholder="Name eingeben..." required aria-required="true">
            </div>
          </div>

          <div class="form-group" ng-class="{ 'has-error': contactform.inputEmail.$invalid && submitted }">
            <label for="inputEmail" class="col-lg-2 control-label">Email *</label>
            <div class="col-lg-10">
              <input ng-model="formData.inputEmail" type="email" class="form-control" id="inputEmail" name="inputEmail" placeholder="Email eingeben..." required aria-required="true">
            </div>
          </div>

          <div class="form-group" ng-class="{ 'has-error': contactform.inputSubject.$invalid && submitted }">
            <label for="inputSubject" class="col-lg-2 control-label">Betreff *</label>
            <div class="col-lg-10">
              <input ng-model="formData.inputSubject" type="text" class="form-control" id="inputSubject" name="inputSubject" placeholder="Betreff eingeben..." required aria-required="true">
            </div>
          </div>

          <div class="form-group" ng-class="{ 'has-error': contactform.inputMessage.$invalid && submitted }">
            <label for="inputMessage" class="col-lg-2 control-label">Nachricht *</label>
            <div class="col-lg-10">
              <textarea ng-model="formData.inputMessage" class="form-control" rows="4" id="inputMessage" name="inputMessage" placeholder="Nachricht eingeben..." required aria-required="true"></textarea>
            </div>
          </div>

          <div class="form-group">
            <div class="col-lg-offset-2 col-lg-10">
              <button type="submit" class="btn btn-primary" ng-disabled="submitButtonDisabled">Nachricht senden</button>
            </div>
          </div>

        </form>

        <p ng-class="result" style="padding: 15px; margin: 0;">{{ resultMessage }}</p>

      </div>
    </div>
  </div>
</div>
