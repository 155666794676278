<div class="aside aside-cart ng-scope right am-slide-right" tabindex="-1" role="dialog" style="display: block;">
  <div class="aside-dialog">

    <div class="aside-content" ng-controller="DefaultCtrl as home">
      <div class="aside-header" ng-show="title">
        <button type="button" class="close" ng-click="$hide()"><i class="fa fa-times"></i></button>
        <h4 class="aside-title ng-binding" ng-bind="title">Suche</h4>
      </div>

      <div ng-cloak class="aside-body">
        <div class="col-xs-*">
          <script>
            (function() {
              var cx = '004176865626214332446:WMX480399437';
              var gcse = document.createElement('script');
              gcse.type = 'text/javascript';
              gcse.async = true;
              gcse.src = 'https://cse.google.com/cse.js?cx=' + cx;
              var s = document.getElementsByTagName('script')[0];
              s.parentNode.insertBefore(gcse, s);
            })();
          </script>
          <gcse:search></gcse:search>
        </div>
      </div>

      <div class="aside-footer">
        <button type="button" class="btn btn-default btn-sm" ng-click="$hide()">schliessen</button>
      </div>
    </div>

  </div>
</div>





