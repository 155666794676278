///<reference path='../../min.references.ts' />

module Home.Data {
  'use strict';

  export interface IMap {
    "x": number;
    "y": number;
    "zoom": number;
  }
}
