<div class="aside ng-scope right am-slide-right" tabindex="-1" role="dialog" style="display: block;">
  <div class="aside-dialog">

    <div class="aside-content">
      <div class="aside-header" ng-show="title">
        <button type="button" class="close" ng-click="$hide()"><i class="fa fa-times"></i></button>
        <h4 class="aside-title ng-binding" ng-bind="title">Suchen</h4>
      </div>

      <div class="aside-body">
        <div class="col-xs-*">
          <h4 class="aside-title">Anzahl Bildergalerien: <span class="label label-default">{{filteredGalleries.length}}</span></h4>
          <div class="form-group">
            <label for="activity" class="sr-only">Nach Aktivität filtern:</label>
            <select class="form-control" id="activity" ng-model="home.activity"
                    ng-options="activity.id as activity.name for activity in home.activityTags"
                    ng-init="home.activity=home.activity||home.activityTags[0].id" ng-change="home.triggerScroll();">
            </select>
          </div>
          <div class="form-group">
            <label for="country" class="sr-only">Nach Land filtern:</label>
            <select class="form-control" id="country" ng-model="home.country"
                    ng-options="country.id as country.name for country in home.countryTags"
                    ng-init="home.country=home.country||home.countryTags[0].id" ng-change="home.triggerScroll();">
            </select>
          </div>
          <div class="form-group">
            <label for="region" class="sr-only">Nach Region filtern:</label>
            <select class="form-control" id="region" ng-model="home.region"
                    ng-options="region.id as region.name for region in home.regionTags"
                    ng-init="home.region=home.region||home.regionTags[0].id" ng-change="home.triggerScroll();">
            </select>
          </div>
          <div class="form-group">
            <label for="fulltext" class="sr-only">Suche:</label>
            <input class="form-control" type="text" placeholder="Suchen..." ng-model="home.query"
                   ng-model-options="{debounce:500}" id="fulltext" ng-change="home.triggerScroll();"/>
          </div>
        </div>
      </div>

      <div class="aside-footer">
        <a href="suche" class="btn btn-default btn-sm" role="button">zurücksetzen</a>
        <button type="button" class="btn btn-default btn-sm" ng-click="$hide()">schliessen</button>
      </div>
    </div>

  </div>
</div>
