///<reference path='../../min.references.ts' />
module Home.IndexCtrl {
  'use strict';

  interface IRouteParams extends ng.route.IRouteParamsService {
    countryId: string;
    regionId: string;
    activityId: string;
  }

  class IndexCtrl {

    ctrlName: string;
    galleries : Array<Home.Data.IGallery>;
    query: string;
    country: string;
    region: string;
    activity: string;
    themeFilter: string;
    title: string;
    metaTitle: string;
    metaDescription: string;
    metaKeywords: string;
    ogImage: string;
    countryTags: Array<Home.Data.ITag>;
    regionTags: Array<Home.Data.ITag>;
    activityTags: Array<Home.Data.ITag>;
    tags: Array<Array<Home.Data.ITag>>;
    orderby: string;
    addMoreItems: any;
    triggerScroll: any;
    items: any;
    filteredGalleries: Array<Home.Data.IGallery>;
    hasInfiniteScroll: boolean;
    serverUrl: any;
    canonicalUrl: any;

    // $inject annotation.
    // It provides $injector with information about dependencies to be injected into constructor
    // it is better to have it close to the constructor, because the parameters must match in count and type.
    // See http://docs.angularjs.org/guide/di
    public static $inject = [
      '$window',
      '$filter',
      '$scope',
      '$log',
      '$location',
      '$routeParams',
      '$http',
      'serverUrl',
      'Repository',
      'Tagging'];

    // dependencies are injected via AngularJS $injector
    constructor(private $window : ng.IWindowService,
                private $filter : ng.IFilterService,
                private $scope : any,
                private $log : ng.ILogService,
                private $location : ng.ILocationService,
                private $routeParams: IRouteParams,
                private $http : ng.IHttpService,
                private _serverUrl_ : any,
                private repository : Home.Services.IRepository,
                private tagging : Home.Services.ITagging) {

      // redirect page with .html extension to angular page
      if ($window.location.href.indexOf(".html") > 0) {
        $window.location.href = window.location.href.substring(0, $window.location.href.indexOf(".html"));
      }

      var vm = this;
      vm.ctrlName = 'IndexCtrl';
      //$log.debug("ctrlName: " + vm.ctrlName);
      vm.serverUrl = _serverUrl_;

      vm.galleries = vm.repository.getGalleries();
      vm.galleries = $filter('orderBy')(vm.galleries, '-date');
      vm.themeFilter = vm.$location.path();
      vm.hasInfiniteScroll = vm.$location.path().indexOf('/index') === 0? true : false;
      // title for travel collections
      vm.title = vm.getTitle(vm.themeFilter);
      vm.ogImage = vm.getOgImage(vm.themeFilter);
      vm.canonicalUrl = vm.serverUrl.host + vm.themeFilter;

      vm.country = $routeParams.countryId;
      vm.region = $routeParams.regionId;
      vm.activity = $routeParams.activityId;

      vm.tags = vm.tagging.getTags();
      vm.countryTags = vm.tagging.getCountryTags();
      vm.regionTags = vm.tagging.getRegionTags();
      vm.activityTags = vm.tagging.getActivityTags();
      // change sort order for travel indices
      vm.orderby = vm.themeFilter.indexOf('/exped/')<0 ? '-date' : 'date';

      // generate meta tags from route params
      var country = vm.country  ?
        $filter('convertIdToProperty')(vm.country, vm.tags, 'countries','name') : "";
      var region = vm.region ?
        $filter('convertIdToProperty')(vm.region, vm.tags, 'regions','name') : "";
      var activity = vm.activity ?
        $filter('convertIdToProperty')(vm.activity, vm.tags, 'activities','name') : "";


      if (country) {
        vm.metaTitle = "bergauf.ch Bildergalerien nach Land: " +country;
        vm.metaDescription = "Suche nach Land " + country + " in den Bildergalerien im Outdoor Photo-Blog";
        vm.metaKeywords = "Outdoor, Fotoblog, Bildergalerien, Land, "+country;
      } else if (region) {
        vm.metaTitle = "bergauf.ch Bildergalerien nach Region: " +region;
        vm.metaDescription = "Suche nach Region " + region + " in den Bildergalerien im Outdoor Photo-Blog";
        vm.metaKeywords = "Outdoor, Fotoblog, Bildergalerien, Region, "+region;
      } else if (activity) {
        vm.metaTitle = "bergauf.ch Bildergalerien nach Aktivität: " +activity;
        vm.metaDescription = "Tourensuche nach Aktivität " + activity + " im Outdoor Photo-Blog";
        vm.metaKeywords = "Outdoor, Fotoblog, Bildergalerien, Aktivität, "+activity;
      } else {
        vm.metaTitle = "Willkommen beim Outdoor Fotoblog bergauf.ch";
        vm.metaDescription = "Outdoor Photo-Blog und Gipfelbuch zu den Themen Skitouren, Bergsteigen, MTB und Reisen";
        vm.metaKeywords = "Gipfelbuch, Outdoor, Photo, Skitouren, Hochtouren, Bergsteigen, MTB, Reisen";
      }

      // ToDo: Do not reset on each location change ?
      vm.items = vm.galleries.slice(0, 50);

      vm.addMoreItems = function() {
        if (vm.items.length < vm.galleries.length) {
          vm.items = vm.galleries.slice(0, vm.items.length + 50);
          console.log('addMoreItems called. items: ' + vm.items.length);
        }
      };

      vm.triggerScroll = function() {
        // load all app. 350 galleries after a search filter have been applied
        for (var i = 0; i<7; i++) {
          $scope.$emit('list:filtered');
        }
        //console.log('triggerScroll called. items: ' + vm.items.length);
      };

    }

    // ToDo: content shouldn't be in a controller
    private getTitle(path:string):string {
      var title;
      switch (path) {
        case '/bergtouren':
          title = 'Bergtouren';
          break;
        case '/skitouren':
          title = 'Skitouren';
          break;
        case '/exped/reise-nach-kambodscha':
          title = 'Kambodscha - Rundreise';
          break;
        case '/exped/reise-nach-kolumbien':
          title = 'Kolumbien - In 4.5 Wochen das Land entdecken';
          break;
        case '/exped/reise-nach-sulawesi':
          title = 'Sulawesi - In 4 Wochen von Süden nach Norden';
          break;
        case '/exped/reise-nach-island':
          title = 'Island - Einmal rund um die Vulkaninsel';
          break;
        case '/exped/reise-nach-cabo-verde':
          title = 'Kapverden - Inseln São Vicente, Santo Antão und Santiago';
          break;
        case '/exped/reise-nach-kuba':
          title = 'Kuba - Unterwegs in Kuba';
          break;
        case '/exped/reise-nach-myanmar':
          title = 'Burma - eine Reise durch Myanmar';
          break;
        case '/exped/oman':
          title = 'Oman';
          break;
        case '/exped/skitouren-im-iran':
          title = "Iran - Skitouren im Zardkouh- und Alborz-Gebirge";
          break;
        case '/exped/eastgreenland06':
          title = "Ost-Grönland - Karale Ski-Haute Route (Bergwelt)";
          break;
        case '/exped/maroc04':
          title = "Marokko - Skitouren im Hohen Atlas";
          break;
        case '/exped/mtb-reise-ladakh':
          title = 'Ladakh - MTB-Tour durch das kleine Tibet (bike adventure tours Bikereise)';
          break;
        case '/exped/altiplano':
          title = 'Altiplano Argentina - Bikeabenteuer in Nordargentinien (bike adventure tours Bikereise)';
          break;
        case '/exped':
          title = 'Reisen';
          break;
        case '/panoramas':
          title = 'Panoramas';
          break;
        default:
          title = 'Übersicht';
      }

      return title;
    }

    // ToDo: content shouldn't be in a controller
    private getOgImage(path:string):string {
      var ogImage;
      switch (path) {
        case '/index':
          ogImage = '/skitouren/undri-triftlimi/skitour-trifthuette-undri-triftlimi-realp-bild-17.jpg';
          break;
        case '/bildergalerien-nach-outdoor-aktivitaet/reise':
          ogImage = '/exped/reise-nach-island/sueden-vik-reykjanes/sueden-vik-reykjanes-bild-6.jpg';
          break;
        case '/exped/reise-nach-sulawesi':
          ogImage = '/exped/reise-nach-sulawesi/bahia-tomini-malenge/bahia-tomini-togian-islands-bild-18.jpg';
          break;
        case '/exped/reise-nach-island':
          ogImage = '/exped/reise-nach-island/norden-godafoss-husavik/norden-godafoss-husavik-bild-23.jpg';
          break;
        case '/exped/reise-nach-cabo-verde':
          ogImage = '/exped/reise-nach-cabo-verde/fontainhas-santo-antao/fontainhas-santo-antao-kapverden-bild-16.jpg';
          break;
        case '/exped/reise-nach-kuba':
          ogImage = '/exped/reise-nach-kuba/havanna/kubareise-havanna-bild-23.jpg';
          break;
        case '/exped/reise-nach-myanmar':
          ogImage = '/exped/reise-nach-myanmar/namshan/burmareise-namhsan_31.jpg';
          break;
        case '/exped/oman':
          ogImage = '/exped/oman/wahiba/0782.jpg';
          break;
        case '/exped/skitouren-im-iran':
          ogImage = "/exped/skitouren-im-iran/damavand_highcamp/skitour-goosfandsara-bargah-sevom-bild-25.jpg";
          break;
        case '/exped/eastgreenland06':
          ogImage = "/exped/eastgreenland06/060505/1702.jpg";
          break;
        case '/exped/maroc04':
          ogImage = "/exped/maroc04/040302/cd1_253.jpg";
          break;
        case '/exped/mtb-reise-ladakh':
          ogImage = '/exped/mtb-reise-ladakh/baralachala/mtb-tour-baralacha-la-pass_18.jpg';
          break;
        case '/exped/altiplano':
          ogImage = '/exped/altiplano/salinasgrandes/0357.jpg';
          break;
        case '/panoramas':
          ogImage = '';
          break;

        case '/bildergalerien-nach-outdoor-aktivitaet/skitour':
          ogImage = '/skitouren/undri-triftlimi/skitour-trifthuette-undri-triftlimi-realp-bild-17.jpg';
          break;
        case '/bildergalerien-nach-outdoor-aktivitaet/bergtour':
          ogImage = '/bergtouren/silvrettahorn/hochtour-alp-sardasca-silvrettahorn-bild-8.jpg';
          break;
        case '/bildergalerien-nach-outdoor-aktivitaet/hochtour':
          ogImage = '/bergtouren/silvrettahorn/hochtour-alp-sardasca-silvrettahorn-bild-8.jpg';
          break;
        case '/bildergalerien-nach-outdoor-aktivitaet/alpinwanderung':
          ogImage = '/bergtouren/engelberger-rotstock/alpine-wanderung-engelberger-rotstock-bild-4.jpg';
          break;
        case '/bildergalerien-nach-outdoor-aktivitaet/bike-and-hike':
          ogImage = '/bergtouren/silberen/bergwanderung-silberen-3.jpg';
          break;
        case '/bildergalerien-nach-outdoor-aktivitaet/mtb':
          ogImage = '/velotouren/mtb-walliser-haute-route/mtb-walliser-haute-route-95.jpg';
          break;
        case '/bildergalerien-nach-outdoor-aktivitaet/velotour':
          ogImage = '/velotouren/grandes_alpes/rennradtour_route_des_grandes_alpes_3633.jpg';
          break;

        default:
          ogImage = '';
      }

      return ogImage;
    }

  }


  /**
   * @ngdoc object
   * @name index.controller:IndexCtrl
   *
   * @description
   *
   */
  angular
    .module('home')
    .controller('IndexCtrl', IndexCtrl);
}
